import { h } from 'preact';
import { Router } from 'preact-router';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Profile from '../routes/profile';

const App = () => (
  <div id='app'>
    <Router>
      <Profile path='/' />
      <Home path='/home' />
    </Router>
  </div>
);

export default App;
